import Cookies from 'js-cookie'
import { Globe } from 'lucide-react'
import { useRouter } from 'next/router'

export const LanguageSwitcher = () => {
  const router = useRouter()

  const onSwitch = () => {
    const locale = router.locale === 'en' ? 'zh' : 'en'
    Cookies.set('NEXT_LOCALE', locale, { expires: 31536000000 })
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale })
  }

  return (
    <div className="flex cursor-pointer items-center gap-1" onClick={() => onSwitch()}>
      <LangText text="文" active={router.locale === 'zh'} />
      <Globe size={16} />
      <LangText text="A" active={router.locale === 'en'} />
    </div>
  )
}

function LangText({ text, active }: { text: string; active: boolean }) {
  return (
    <p
      className="font-bold"
      style={{ fontSize: active ? '14px' : '12px', color: active ? '#fff' : '#999' }}
    >
      {text}
    </p>
  )
}
